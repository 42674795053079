import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  ConfirmActionPopover,
  IconButton,
  Pointer,
  PointerSmall,
  ScrollBar,
  Select,
  SelectByGroupsMultiple,
  Setting,
  TextField,
  ToggleSwitch,
  WarningOutlinedRotate,
} from '@gmini/ui-kit'
import { Controller, useForm } from 'react-hook-form'

import { equals } from 'ramda'

import { useStore } from 'effector-react'

import * as smApi from '@gmini/sm-api-sdk'

import { useNavigate } from 'react-router-dom'

import { FieldError, FieldHint, FieldHintText } from '@gmini/components'

import {
  AssigneeListItem,
  PROJECT_URN,
  SELECTED_TAB,
  queryParser,
} from '@gmini/helpers'

import * as api from '@gmini/chm-api-sdk'
import qs from 'query-string'

import { fetchTypes, templateTypes$ } from '../../templateTypes.store'

import { ChecklistManagerWrapper } from '../../organisms/ChecklistManagerWrapper'

import { TabIndex } from '../../../constants'

import { BackButton } from '../../atoms'

import { assigneeGroupList$ } from '../../assigneeGroupList'

import {
  AutoCreateLabel,
  Container,
  FieldContainer,
  FieldLabel,
  FooterForm,
  FormTitle,
  FormWrapper,
  IssuesLabel,
  SettingTemplateContainer,
  SettingTemplateItem,
  SettingTemplateWrapper,
  Subtitle,
  SwitcherSection,
  SwitcherSectionRow,
  TextArea,
} from './CreateTemplatePage.styled'
import { createTemplate } from './createTemplate.store'
import {
  fetchProjectList,
  fetchProjectListPending$,
  projectList$,
} from './model'

type FormValue = {
  name: string
  description: string
  type: api.Template.TemplateType | null
  allowSectionAssignee: boolean
  project: smApi.Project | null
  initiators: Array<AssigneeListItem>
}

const defaultValues = {
  name: '',
  description: '',
  type: null,
  allowSectionAssignee: false,
  signatureRequired: true,
  project: null,
  initiators: [],
}

const requiredErrorMessage = 'Это поле является обязательным'

const maxDescriptionLengthErrorMessage =
  'Максимальная допустимая длина описания - 1024 символа'

const maxLengthErrorMessage =
  'Максимальная допустимая длина названия - 256 символов'

export const CreateTemplatePage = () => {
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormValue>({ defaultValues, mode: 'onChange' })
  const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)
  const createTemplatePending = useStore(createTemplate.pending$)
  const fetchTypesPending = useStore(fetchTypes.pending$)
  const templateTypes = useStore(templateTypes$)
  const fetchProjectListPending = useStore(fetchProjectListPending$)
  const projectList = useStore(projectList$)
  const projectUrn = queryParser({ key: PROJECT_URN }) as string
  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => projectUrn === urn) || null,
    [projectList, projectUrn],
  )
  const assigneeGroupList = useStore(assigneeGroupList$)

  useEffect(() => {
    if (selectedProject) {
      setValue('project', selectedProject)
    }
  }, [selectedProject, setValue])

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  const handleClosePopover = () => {
    setAnchorElPopover(null)
  }

  const onSubmit = (
    data: FormValue,
    redirectTo: 'templateEdit' | 'templateList',
  ) => {
    try {
      if (!data.project) {
        throw new Error('Не выбран проект')
      }

      createTemplate({
        allowSectionAssignee: data.allowSectionAssignee,
        templateTypeId: data.type?.id || null,
        signatureRequired: true,
        description: data.description,
        name: data.name,
        projectUrn: data.project.urn,
        initiators: data.initiators.map(({ source, assigneeId }) => ({
          initiatorId: assigneeId,
          source,
        })),
      }).then(({ id }) => {
        const prev = qs.parse(window.location.search)
        const search = qs.stringify({
          ...prev,
          [SELECTED_TAB]: TabIndex.TemplateList.toString(),
        })

        navigate({
          pathname:
            redirectTo === 'templateEdit' ? `/template/edit/${id}` : '/',
          search,
        })
      })
    } catch (error) {
      console.error('error :>> ', error)
    }
  }

  const watchFields = watch()

  const onClickHistoryBack = useCallback(
    event => {
      const haveChanges = !equals(watchFields, defaultValues)

      if (haveChanges) {
        setAnchorElPopover(event.target as Element)
        return
      }

      const prev = qs.parse(window.location.search)
      const search = qs.stringify({
        ...prev,
        [SELECTED_TAB]: TabIndex.TemplateList.toString(),
      })

      navigate({ pathname: '/', search })
    },
    [watchFields, navigate],
  )

  const onSaveAndExit = () => {
    handleClosePopover()

    handleSubmit(data => onSubmit(data, 'templateList'))()
  }

  const onChangeProject = useCallback(
    (project, field) => {
      const prev = qs.parse(window.location.search)
      const search = qs.stringify({ ...prev, [PROJECT_URN]: project.urn })

      navigate(
        {
          pathname: '/template/create',
          search,
        },
        { replace: true },
      )
      field.onChange(project)
    },
    [navigate],
  )

  return (
    <ChecklistManagerWrapper selectedProject={selectedProject}>
      <Container>
        <ConfirmActionPopover
          message={
            <>
              Вы уверены, что хотите выйти из мастера создания шаблона без
              сохранения внесенных изменений?
            </>
          }
          title='Выйти из мастера создания шаблона'
          anchorEl={anchorElPopover}
          handleClose={handleClosePopover}
          maxWidth='700px'
          actions={
            <>
              <Button
                data-test-id='cancelToExit'
                size='regular'
                color='secondary'
                onClick={handleClosePopover}
              >
                Отмена
              </Button>
              <Button
                data-test-id='exitWithoutSaving'
                color='secondary'
                onClick={() => {
                  handleClosePopover()
                  reset()

                  const prev = qs.parse(window.location.search)
                  const search = qs.stringify({
                    ...prev,
                    [SELECTED_TAB]: TabIndex.TemplateList.toString(),
                  })

                  navigate({ pathname: '/', search })
                }}
                size='regular'
              >
                Выйти без сохранения
              </Button>

              <Button
                data-test-id='saveAndExit'
                color='primary'
                disabled={createTemplatePending}
                onClick={onSaveAndExit}
                size='regular'
              >
                Сохранить черновик и выйти
              </Button>
            </>
          }
        />
        <div>
          <BackButton
            disabled={createTemplatePending}
            onClick={onClickHistoryBack}
          >
            <Pointer />
            Назад
          </BackButton>
        </div>

        <ScrollBar>
          <FormWrapper>
            <FormTitle>Новый шаблон чек-листа</FormTitle>

            <FieldContainer>
              <FieldLabel required>Проект</FieldLabel>
              <Controller
                name='project'
                control={control}
                rules={{
                  required: { message: requiredErrorMessage, value: true },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder='Выберите проект'
                    error={Boolean(errors.project)}
                    options={projectList}
                    getOptionLabel={(option: smApi.Project) => option.name}
                    emptyOptionListMessage='Нет доступных совпадений'
                    disabled={createTemplatePending || fetchProjectListPending}
                    onChange={project => onChangeProject(project, field)}
                    allowDelete={false}
                    lockSelect={!!selectedProject}
                  />
                )}
              />
              <FieldError hidden={!errors.project}>
                {errors.project && 'message' in errors.project
                  ? errors.project?.message
                  : null}
              </FieldError>
            </FieldContainer>

            <FieldContainer>
              <FieldLabel required>Название шаблона</FieldLabel>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: { message: requiredErrorMessage, value: true },
                  maxLength: {
                    message: maxLengthErrorMessage,
                    value: 256,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Задайте название'
                    error={Boolean(errors.name)}
                    disabled={createTemplatePending}
                  />
                )}
              />
              <FieldError hidden={!errors.name}>
                {errors.name?.message}
              </FieldError>
            </FieldContainer>

            <FieldContainer>
              <FieldLabel>Описание</FieldLabel>
              <Controller
                name='description'
                control={control}
                rules={{
                  maxLength: {
                    message: maxDescriptionLengthErrorMessage,
                    value: 1024,
                  },
                }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    placeholder='Задайте описание'
                    error={Boolean(errors.description)}
                    disabled={createTemplatePending}
                  />
                )}
              />
              <FieldError hidden={!errors.description}>
                {errors.description?.message}
              </FieldError>
            </FieldContainer>

            <FieldContainer>
              <FieldLabel>Дисциплина шаблона</FieldLabel>
              <Controller
                name='type'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder='Задайте дисциплину'
                    error={Boolean(errors.type)}
                    options={templateTypes}
                    getOptionLabel={(option: api.Template.TemplateType) =>
                      option.name
                    }
                    emptyOptionListMessage='Нет доступных совпадений'
                    disabled={createTemplatePending || fetchTypesPending}
                  />
                )}
              />
              <FieldError hidden={!errors.type}>
                {errors.type && 'message' in errors.type
                  ? errors.type?.message
                  : null}
              </FieldError>
            </FieldContainer>

            <FieldContainer>
              <FieldLabel>Инициатор чек-листа</FieldLabel>
              <Controller
                name='initiators'
                control={control}
                render={({ field }) => (
                  <SelectByGroupsMultiple
                    {...field}
                    placeholder='Выберите инициатора'
                    error={Boolean(errors.initiators)}
                    groups={assigneeGroupList}
                    getOptionLabel={(option: AssigneeListItem) => option.label}
                    emptyOptionListMessage='Нет доступных совпадений'
                    disabled={createTemplatePending || fetchTypesPending}
                  />
                )}
              />
              {!watchFields.initiators.length && (
                <FieldHint>
                  <WarningOutlinedRotate color='rgba(53, 59, 96, 0.25)' />
                  <FieldHintText transparent>
                    Только выбранные инициаторы смогут создать
                    <br /> чеклист на основе этого шаблона.
                  </FieldHintText>
                </FieldHint>
              )}
            </FieldContainer>

            <SwitcherSection>
              <SwitcherSectionRow>
                <SettingTemplateContainer>
                  <Subtitle>Настройка замечаний</Subtitle>
                  <SettingTemplateWrapper>
                    <SettingTemplateItem>
                      <IssuesLabel>Замечания не определены</IssuesLabel>
                      <IconButton disabled>
                        <Setting />
                      </IconButton>
                    </SettingTemplateItem>
                    <SettingTemplateItem>
                      <AutoCreateLabel>Автосоздание</AutoCreateLabel>
                      <ToggleSwitch
                        size='medium'
                        color='#4C5ECF'
                        hoverColor='#4A60ED'
                        selectedColor='#2235AA'
                        disabled
                      />
                    </SettingTemplateItem>
                  </SettingTemplateWrapper>
                </SettingTemplateContainer>
              </SwitcherSectionRow>

              <SwitcherSectionRow>
                <Subtitle>
                  Разрешить добавление ответственных на секции
                </Subtitle>
                <Controller
                  name='allowSectionAssignee'
                  control={control}
                  render={({ field }) => (
                    <ToggleSwitch
                      {...field}
                      active={field.value}
                      onToggle={(value: boolean) => field.onChange(value)}
                      color='#4C5ECF'
                      hoverColor='#4A60ED'
                      selectedColor='#2235AA'
                      size='medium'
                      disabled={createTemplatePending}
                    />
                  )}
                />
              </SwitcherSectionRow>
              <SwitcherSectionRow>
                <Subtitle>Требовать обязательные подписи в чек-листе</Subtitle>
                <ToggleSwitch
                  color='#4C5ECF'
                  hoverColor='#4A60ED'
                  selectedColor='#2235AA'
                  size='medium'
                  disabled
                />
              </SwitcherSectionRow>
            </SwitcherSection>

            <FooterForm>
              <Button
                data-test-id='continue'
                fullWidth
                rightIcon={<PointerSmall />}
                onClick={handleSubmit(data => onSubmit(data, 'templateEdit'))}
                disabled={createTemplatePending}
              >
                Далее
              </Button>
            </FooterForm>
          </FormWrapper>
        </ScrollBar>
      </Container>
    </ChecklistManagerWrapper>
  )
}
