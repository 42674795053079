import { RoundedCross } from '@gmini/ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  SwitchProjectBadgeWrapper,
  TextFieldWithPreview,
} from '@gmini/components'

import { useStore } from 'effector-react'

import { ProjectBadge } from '@gmini/common'

import { InstanceStatus } from '@gmini/chm-api-sdk'

import {
  ChecklistManagerWrapper,
  TemplateDetailsPanel,
  ChecklistForm,
} from '../../organisms'
import { Scheme } from '../../molecules'

import {
  HeaderLeftPanelEditPage,
  LeftPanelEditPage,
  ContentLeftPanelEditPage,
} from '../../atoms'

import { useTemplatePopulated } from '../../useTemplatePopulated'

import {
  fetchProjectListPending$,
  fetchProjectList,
  projectList$,
} from './model'

import {
  Wrapper,
  PreviewHeader,
  PreviewHeaderTitle,
  ClosePreviewButton,
  PreviewBgWrapper,
  PreviewChildrenWrapper,
  Container,
  Content,
} from './PreviewChecklistPage.styled'

export const PreviewChecklistPage = () => {
  const navigate = useNavigate()
  const { templateId } = useParams<{ templateId: string }>()
  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0)

  const {
    fetchMostRecentTemplate,
    fetchTemplatePopulatedPending,
    templatePopulated,
  } = useTemplatePopulated()
  const projectList = useStore(projectList$)

  const fetchProjectListPending = useStore(fetchProjectListPending$)

  const selectedProject = useMemo(
    () =>
      projectList.find(({ urn }) => urn === templatePopulated?.projectUrn) ||
      null,
    [templatePopulated?.projectUrn, projectList],
  )

  // eslint-disable-next-line no-empty-function
  const emptyFunction = () => {}

  useEffect(() => {
    if (templateId) {
      fetchMostRecentTemplate({ id: Number(templateId) })
    }
  }, [fetchMostRecentTemplate, templateId])

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  if (!templatePopulated && !fetchTemplatePopulatedPending) {
    return <>Не найден шаблон</>
  }

  return (
    <ChecklistManagerWrapper
      selectedProject={selectedProject}
      loading={fetchTemplatePopulatedPending}
      brandSecondary={
        <SwitchProjectBadgeWrapper>
          <ProjectBadge
            projectName={selectedProject?.name || ''}
            loading={fetchProjectListPending}
          />
        </SwitchProjectBadgeWrapper>
      }
    >
      {templatePopulated && (
        <Wrapper>
          <PreviewHeader>
            <PreviewHeaderTitle>
              Режим предпросмотра чек-листа
            </PreviewHeaderTitle>
            <ClosePreviewButton onClick={() => navigate(-1)}>
              <RoundedCross />
            </ClosePreviewButton>
          </PreviewHeader>
          <PreviewBgWrapper>
            <PreviewChildrenWrapper>
              <Container>
                <Content>
                  <LeftPanelEditPage>
                    <HeaderLeftPanelEditPage>
                      <TextFieldWithPreview
                        value={templatePopulated.name}
                        onChange={emptyFunction}
                        disabled
                      />
                    </HeaderLeftPanelEditPage>
                    <ContentLeftPanelEditPage>
                      <Scheme
                        sections={templatePopulated.sections}
                        title='Схема чек-листа'
                        selectedSectionIdx={selectedSectionIdx}
                        setSelectedSectionIdx={setSelectedSectionIdx}
                        onCreateSection={emptyFunction}
                        inInstance
                      />
                      <ChecklistForm
                        selectedSectionIdx={selectedSectionIdx}
                        setSelectedSectionIdx={setSelectedSectionIdx}
                        closeStatus={emptyFunction}
                        onUpdateAnswer={emptyFunction}
                        instanceStatus={InstanceStatus.Enum.DRAFT}
                        editorPending={false}
                        template={templatePopulated}
                        answers={[]}
                      />
                    </ContentLeftPanelEditPage>
                  </LeftPanelEditPage>
                  <TemplateDetailsPanel
                    template={templatePopulated}
                    id={templatePopulated.id}
                    version={templatePopulated.version}
                    disabled
                    inPreview
                    onToggleEditing={emptyFunction}
                    editing={false}
                  />
                </Content>
              </Container>
            </PreviewChildrenWrapper>
          </PreviewBgWrapper>
        </Wrapper>
      )}
    </ChecklistManagerWrapper>
  )
}
